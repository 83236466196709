<template>
  <div
    v-if="!statusStore.isGenerating && artifact"
    class="tag-wrapper"
  >
    <div class="tag">
      <v-chip
        class="chip"
        v-for="tag in artifact.tags"
        :key="tag.value"
      >
        <template v-if="tag.category && tag.value">
          {{ tag.category }} : {{ tag.value }}
        </template>
      </v-chip>
    </div>
  </div>
</template>

<script setup lang="ts">
const statusStore = useStatusStore()
const artifactStore = useArtifactStore()
const artifact = computed(() => artifactStore.displayedArtifact)
</script>

<style lang="scss" scoped>
.tag-wrapper {
  height: auto;
  width: 100%;
  padding: 0.5rem;
  align-items: center;
  background-color: rgb(var(--v-theme-background));

  > .tag {
    gap: 0.5rem;
    display: flex;
    flex-wrap: wrap;

    > .chip {
      height: 20px;
      min-width: 50px;
      font-size: 0.65rem;
      background-color: rgb(var(--v-theme-primary-light));
    }
  }
}
</style>
